/* 
  TODO list 
  [ ] оформить внешний вид страницы ( на всякий случай )!!! 
   
*/ 
<template>
  <div>
    <ModalLogin />
  </div>
</template>

<script>
import ModalLogin from '~/components/Modals/ModalContent/ModalLogin.vue';

export default {
  auth: false,
  components: { ModalLogin },
  layout: 'default',
};
</script>
